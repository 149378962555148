<template>
  <div class="case">
    <div style="background: #28a84a !important;">
      <h1 class="text-center top-text py-16 white--text">Case Studies</h1>
    </div>
    <v-row class="pa-16 cont">
      <div class="card" v-for="(m, i) in items" :key="i">
        <v-hover v-slot="{hover}">
          <v-card
            :outlined="true"
            class="card-cont mb-10"
            max-width="400"
            :style="
              hover
                ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                : ''
            "
          >
            <div class="pa-4 img">
              <div style="">
                <v-img :src="m.img" width="100%"></v-img>
              </div>
            </div>

            <v-card-title
              class="justify-start title"
              :style="hover ? 'color:#28A84A' : ''"
            >
              <!-- {{ title }} -->
              {{ m.title }}
            </v-card-title>

            <v-card-subtitle class="pt-2">
              {{ m.content }}
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                color=""
                text
                :style="hover ? 'color:#28A84A' : ''"
                :to="m.link"
                style="font-size:10px"
              >
                Read more
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="" text style="font-size:10px">
                10 March, 2021
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </div>
    </v-row>
  </div>
</template>

<script>
import img1 from '../../../assets/case/image (13).png';
import img2 from '../../../assets/case/image (15).png';
// import img3 from '../../../assets/case/image (12).png';
// import img4 from '../../../assets/case/image (14).png';

export default {
  name: 'Case',
  data: () => ({
    items: [
      {
        img: img1,
        content:
          'To showcase the efficiency of Gricd cold boxes in retaining the quality of temperature sensitive commodities,',
        title: 'Case Study: Juhel Pharmaceutical',
        link: '/about/case-studies-2',
      },
      {
        img: img2,
        content:
          'DrugStoc improves on their cold chain facility and ensure ensures uninterrupted storage of their temperature sensitve products.',
        title: 'Case Study: DrugStoc',
        link: '/about/case-studies-1',
      },
      // {
      //   img: img3,
      //   content:
      //     'DrugStoc improves on their cold chain facility and ensure ensures uninterrupted storage of their temperature sensitve products.',
      //   title: 'Case Study: DrugStoc',
      //   link: '/about/case-studies-1',
      // },
      // {
      //   img: img4,
      //   content:
      //     'DrugStoc improves on their cold chain facility and ensure ensures uninterrupted storage of their temperature sensitve products.',
      //   title: 'Case Study: DrugStoc',
      //   link: '/about/case-studies-2',
      // },
      // {
      //   img: img2,
      //   content:
      //     'DrugStoc improves on their cold chain facility and ensure ensures uninterrupted storage of their temperature sensitve products.',
      //   title: 'Case Study: DrugStoc',
      //   link: '/about/case-studies-1',
      // },
      // {
      //   img: img3,
      //   content:
      //     'DrugStoc improves on their cold chain facility and ensure ensures uninterrupted storage of their temperature sensitve products.',
      //   title: 'Case Study: DrugStoc',
      //   link: '/about/case-studies-2',
      // },
    ],
    metaInfo() {
      return {
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Home',
                  item: 'https://gricd.com/',
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: 'Case Study',
                  item: 'https://gricd.com/about/case-studies',
                },
              ],
            },
          },
        ],
      };
    },
  }),
};
</script>

<style scopeds lang="scss">
.top-text {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 5rem 0;
  @media (max-width: 1024px) {
    padding: 3rem 0;
  }
}
.cont {
  // justify-content: space-evenly;
  column-gap: 4rem;
  flex-flow: row wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
}
p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150.8%;
  color: #748494;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  border: 0 !important;
  box-shadow: initial;
  filter: drop-shadow(0px 20px 50px rgba(29, 53, 71, 0.1));
  width: 400px;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 769px) {
    width: 23rem;
  }

  .title {
    font-size: 15px !important;
    font-weight: 600;
  }
}
</style>
